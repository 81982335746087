import React from 'react'
import BioCard from './BioCard'
import Tab from 'react-bootstrap/Tab'
import {Container, Row, Col} from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs'
import jobMarkdown from '../../content/resume/jobMarkdown.md'
import academicMarkdown
    from '../../content/resume/academicMarkdown.md'
import skillsMarkdown from '../../content/resume/skillsMarkdown.md'
import Markdown from "react-markdown";


const About = () => {


    const cssStyles = `
        .nav-link {
            color: white; /* Replace with your desired color for inactive tabs */
        }
        .nav-link.active {
            color: black !important; /* Replace with your desired color for the active tab */
            background-color: #B5CACB; /* Replace with your desired color for the active tab */
        }
        .nav-link:hover {
            color: #B5CACB; /* Replace with your desired color for the hover state */
        }
        
            .nav-item .nav-link.active {
        border-bottom: 2px solid #B5CACB !important; /* Customize the color and style of the active indicator */
    }

    `;

    const [jobMarkdownText, setJobMarkdownText] = React.useState('')
    const [academicMarkdownText, setAcademicMarkdownText] = React.useState('')
    const [skillsMarkdownText, setSkillsMarkdownText] = React.useState('')

    React.useEffect(() => {
        const fetchMarkdown = async (markdownPath, setState) => {
            if (!markdownPath) return;
            try {
                const response = await fetch(markdownPath);
                const text = await response.text();
                setState(text);
            } catch (error) {
                console.error('Error fetching markdown:', error);
            }

        }
        fetchMarkdown(jobMarkdown, setJobMarkdownText);
        fetchMarkdown(academicMarkdown, setAcademicMarkdownText);
        fetchMarkdown(skillsMarkdown, setSkillsMarkdownText);

    })

    const tabstyle = {
        backgroundColor: '#838495', /* Change this to your desired color */
        color: 'white' /* Change this to your desired text color */,
        padding: '1em',
        marginBottom: '1em',
        borderRadius: '.5em',
    }
    const activetablestyle = {
        border: '1px solid #4B6479',
    }
    //TODO style the active tab

    return (
        <>
            <style>{cssStyles}</style>


            <BioCard/>
            <br/>
            <br/>
            <div

                style={{
                    margin: "0 auto",
                    maxWidth: "85%",
                    borderRadius: ".5em",
                    // backgroundColor: "#838495",
                    fontSize: "1.5em"


                }}>
                <Tabs
                    defaultActiveKey="Professional Experience"
                    style={{
                        backgroundColor: "#4B6479",
                        // color: "#B5CACB",
                        borderRadius: ".5em",


                    }}
                    justify={true}
                >
                    <Tab eventKey="Professional Experience"
                         title="Professional Experience"
                         style={tabstyle}
                    >
                        <Container

                            style={{
                                backgroundColor: "#8B8CA0",
                                color: "#000",
                                borderRadius: ".5em",
                                padding: "1em",
                                margin: ".9rem auto",
                                border: ".1em solid #4B6479"
                            }}
                        >
                            <Markdown>
                                {jobMarkdownText}
                            </Markdown>
                        </Container>
                    </Tab>
                    <Tab eventKey="Academic" title={"Academic"}
                         style={tabstyle}
                    >
                        <Container

                            style={{
                                backgroundColor: "#8B8CA0",
                                color: "#000",
                                borderRadius: ".5em",
                                padding: "1em",
                                margin: ".9rem auto",
                                border: ".1em solid #4B6479"
                            }}
                        >
                            <Markdown>
                                {academicMarkdownText}
                            </Markdown>
                        </Container>
                    </Tab>
                    <Tab eventKey={"Technical"} title={"Technical"}
                         style={tabstyle}
                    >
                        <Container

                            style={{
                                backgroundColor: "#8B8CA0",
                                color: "#000",
                                borderRadius: ".5em",
                                padding: "1em",
                                margin: ".9rem auto",
                                border: ".1em solid #4B6479"
                            }}
                        >
                            <Markdown>
                                {skillsMarkdownText}
                            </Markdown>
                        </Container>
                    </Tab>
                </Tabs>
            </div>
        </>


    )
}

export default About
