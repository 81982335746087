import React from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import './Home.css'

const Home = () => {
    return (
        
            <div class='home'>
                <img src='/home.gif'></img>
                <br/>
                <br/>
                <p style={{
                    color: '#353A5D', fontSize: '1.5em',
                    fontFamily: 'Montserrat'
                }}>
                    My name is Jam Mogavero. Nice to meet you!
                </p>
            </div>

    )
}

export default Home
