import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
// import testMarkdownStack from '../../../public/markdown/projects/test.md'
import Markdown from 'react-markdown'
import ProjectData from "../../pages/projects/ProjectData";

//IMPORT CSS
import './TestDetailMarkdown.css'
import DataService from "../../services/DataService";
// import '../../App.css';

const ProjectDetail = () => {
    const [backgroundMarkdown, setBackgroundMarkdown] = useState('');
    const [stackMarkdown, setStackMarkdown] = useState('');
    const [backgroundMarkdownPath, setBackgroundMarkdownPath] = useState('');
    const [stackMarkdownPath, setStackMarkdownPath] = useState('');

    const [projectData, setProjectData] = useState({});

    const {path} = useParams();
    const dataService = new DataService()


    useEffect(() => {
        const fetchProjectData = async () => {
            if (!path) return;

            const projectDataFetch = dataService.getProjectDetailFromPath(path);
            if (projectDataFetch) {
                setProjectData(projectDataFetch);
            }
        };

        fetchProjectData();
    }, [path, dataService]);

    useEffect(() => {
        if (projectData.backgroundFile && projectData.techStackFile) {
            setBackgroundMarkdownPath(`/markdown/projects/${projectData.backgroundFile}`);
            setStackMarkdownPath(`/markdown/projects/${projectData.techStackFile}`);
        }
    }, [projectData]);

    useEffect(() => {
        const fetchMarkdown = async (markdownPath, setState) => {
            if (!markdownPath) return;
            try {
                const response = await fetch(markdownPath);
                const text = await response.text();
                setState(text);
            } catch (error) {
                console.error('Error fetching markdown:', error);
            }
        };

        fetchMarkdown(backgroundMarkdownPath, setBackgroundMarkdown);
        fetchMarkdown(stackMarkdownPath, setStackMarkdown);
    }, [backgroundMarkdownPath, stackMarkdownPath]);


    // TODO Links and Images should come from the site data JSON file.

    return (
        <div className='details'>
            <br/>
            <div className='project-detail-title '>{projectData.title}</div>
            <div className='project-detail-section-header'>Project Background
            </div>
            <br/>
            <Markdown className={'project-detail-section-content'}>
                {backgroundMarkdown}
            </Markdown>
            <div className='project-detail-section-header'>Tech Stack</div>
            <br/>
            <Markdown className={'project-detail-section-content'}>
                {stackMarkdown}
            </Markdown>
            <div className='project-detail-section-header'>Links</div>
            <br/>
            {/*Map the links as an unordered list*/}
            {projectData.links && projectData.links.length > 0 ? (
                <ul>
                    {projectData.links.map((link) => (
                        <li>
                            {link.name ? (
                                <a href={link.url ? `https://${link.url}` : '#'}>{link.name}</a>) : ''}
                        </li>
                    ))}
                </ul>
            ) : (
                <div>No links available</div>
            )}


            <div className='project-detail-section-header'>Images</div>
            <br/>
            {projectData.images && projectData.images.length > 0 ? (

                <div className='project-images'>
                    {projectData.images.map((image) => (
                        <><img src={image} alt='project'
                             className='project-image'/>
                        <br/></>
                    ))}
                 </div>
            ) : (
                <div>No images available</div>
            )


            }

        </div>
    )
}

export default ProjectDetail
