import logo from './logo.svg'
import './App.css'

// REACT COMPONENTS
import React from 'react'
import ReactDOM from 'react-dom/client'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Switch
} from 'react-router-dom'

//REACT MARKDOWN
import Markdown from 'react-markdown'
import ReactGA from 'react-ga4';

// BOOTSTRAP COMPONENTS
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import {Button} from 'react-bootstrap'

// UI PAGES
import Home from './components/Home/Home'
import InfoCard from './components/InfoCard'
import InfoCardSandbox from './components/Other/InfoCardSandbox'
import BioCard from './components/About/BioCard'
import ProjectMenu from './components/Projects/ProjectMenu'
import ProjectDetail from "./components/Projects/ProjectDetail";
import {Analytics} from "@vercel/analytics/react";
import AboutPage from "./pages/about/AboutPage";
import HomePage from "./pages/HomePage";

function App() {
    ReactGA.initialize("G-VHX0CQ0DDJ");

    return (
        <div style={{backgroundColor: '#D7DFE0'}}>
            <Navbar
                collapseOnSelect
                fixed='top'
                expand='lg'
                style={{backgroundColor: '#4b6479', height: 'auto'}}
            >
                <Container style={{marginLeft: '0'}}>
                    <Navbar.Brand
                        style={{color: '#B5CACB'}}>Jam@Mogavero.me</Navbar.Brand>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav className='me-auto'>
                            <Nav.Link href='/'>
                                <Button bsPrefix='nav-button'>Home</Button>
                            </Nav.Link>
                            <Nav.Link href='/about'>
                                <Button bsPrefix='nav-button'>About</Button>
                            </Nav.Link>
                            <Nav.Link href='/projects'>
                                <Button bsPrefix='nav-button'>Projects</Button>
                            </Nav.Link>
                            {/*<Nav.Link href='/blog'>*/}
                            {/*    <Button bsPrefix='nav-button'>Blog</Button>*/}
                            {/*</Nav.Link>*/}
                            <Nav.Link
                                href='https://hihello.me/p/e4c33c47-709e-4b1f-8d90-2d4f9cb578a3?sharer_id=BlGVfTm5vpUcL10jy0nk7HSO3lu1'
                                target={"_blank"}>
                                <Button bsPrefix='nav-button'>Contact &
                                    Resume</Button>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Routes>
                <Route path='/about' element={<AboutPage/>}/>
                <Route path='/' element={<HomePage/>}/>
                <Route path='/projects' element={<ProjectMenu/>}/>
                <Route path='/projects/:path' element={<ProjectDetail/>}


                />

                {/*<Route path={'/blog'} element={<BlogMenu/>}/>*/}

            </Routes>
            <Analytics/>
        </div>
    )
}

export default App
