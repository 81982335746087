import projectData from '../../src/pages/projects/ProjectData.js';

class DataService {

    getProjectDetailsFromTitle(title) {
        let func = (check) => {
            return check.title === title;
        }

        return this.getProjectDetail(func);
    }

    getProjectDetailFromPath(path) {
        let func = (check) => {
            return check.details.path === path;
        }

        return this.getProjectDetail(func);
    }

    getProjectDetail(func) {
        let data = projectData

        for (let i = 0; i < data.length; i++) {
            let check = data[i]
            if (func(check)) {

                return {
                    "title": check.title,
                    "backgroundFile": check.details.backgroundFile,
                    "techStackFile": check.details.techStackFile,
                    "links": check.details.links,
                    "images": check.details.images,
                    "path": check.details.path
                }

            }
        }
    }
}

export default DataService
