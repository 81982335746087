const projectData = [
    {
        "title": "WebSocket Dev Tool (STOMP Pub-Sub Support)",
        "overview": {
            "image": "/images/sockets.png",
            "contents": "An ongoing project to fill the lack of STOMP-supporting API debug/test platforms."
        },
        "details": {
            "backgroundFile": "websocketsBackground.md",
            "techStackFile": "websocketsStack.md",
            "links": [

                {
                    "name": "",
                    "url": ""
                }
            ],
            "images": [
                "",
                ""
            ],
            "path": "websockets"
        }
    },
    {
        "title": "PyChat",
        "overview": {
            "image": "/images/pychat-overview.png",
            "contents": "A chat application for multiple clients written in Python, " +
                "using the curses, threads, and multiprocessing libraries."
        },
        "details": {
            "backgroundFile": "pychatBackground.md",
            "techStackFile": "pychatStack.md",
            "links": [

                {
                    "name": "GitHub Repository",
                    "url": "github.com/hoppityhop/pychat"
                }
            ],
            "images": [
                "/images/python-chat.gif",
                "/images/pychat-overview.png"
            ],
            "path": "pychat"
        }
    },
    {
        "title": "Terror Prom",
        "overview": {
            "image": "/images/terror-prom-overview.png",
            "contents": "A real-time mystery game scaling up to 100 sessions with 600 players. Figure out who committed murder at the prom. Was it the cheerleader? The lunchlady?"
        },
        "details": {
            "backgroundFile": "terrorpromBackground.md",
            "techStackFile": "terrorpromStack.md",
            "links": [
                {
                    "name": "Play Terror Prom",
                    "url": "www.terrorprom.com"
                },
                {
                    "name": "Front End Repository",
                    "url": "github.com/hoppityhop/terrorprom-client"
                },
                {
                    "name": "Backend Repository",
                    "url": "github.com/hoppityhop/terror-prom"
                }
            ],
            "images": [
                "/images/terror-prom.png",
                "/images/terror-prom-overview.png",
                ""
            ],
            "path": "terror-prom"
        }
    },
    {
        "title": "mogavero.me",
        "overview": {
            "image": "/home.gif",
            "contents": "My portfolio site, built with React, Bootstrap, and React-Bootstrap. "
        },
        "details": {
            "backgroundFile": "jamdevBackground.md",
            "techStackFile": "jamdevStack.md",
            "links": [
                {
                    "name": "GitHub Repository",
                    "url": "github.com/hoppityhop/jam-builds"
                }
            ],
            "images": [],
            "path": "mogavero-me"
        }
    },]

export default projectData
