import React from "react";
import About from "../../components/About/About";

const AboutPage = () => {
    return (
        <>
            <br/>
            <br/>
            <About/>
        </>
    )
}

export default AboutPage;
