import React from 'react'
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import {Card, Button, Container, Row, Col} from 'react-bootstrap'

/**
 * Responsiveness:
 * - Mobile--image should be on the top in the card and text should be below the image
 * - Desktop--image should be on the left and text should be on the right
 *
 * Header bar of card is the same color as the navbar.
 *
 */

const BioCard = () => {
    return (
        <Card
            style={{
                maxWidth: '90%',
                margin: '0 auto',
                marginTop: '70px',
                backgroundColor: '#838495'
            }}
        >
            <Card.Header
                style={{
                    backgroundColor: '#4b6479',
                    textAlign: 'center',
                    color: '#b5cacb',
                    fontFamily: 'roboto mono'
                }}
            >
                <h1>About Jam</h1>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={12} md={5}>
                        <Card.Img
                            style={{
                                maxWidth: '70%',
                                display: 'block',
                                margin: 'auto',
                                borderRadius: '20%',

                            }}
                            src='jam_headshot.jpg'
                        ></Card.Img>
                    </Col>
                    <Col xs={12} md={7}>
                        <div
                            style={{
                                margin: '5% 5%',
                                fontFamily: 'montserrat',
                                fontSize: '1.5em',
                                color: '#D7DFE0',
                                textAlign: 'left'
                            }}
                        >
                            <Card.Text>
                                <p>I’m Jam, a software engineer and product
                                    manager from Chicago, Illinois, as well as an MS
                                    student in Computer Science (with a
                                    concentration in Machine Learning) at Johns
                                    Hopkins University.
                                </p>
                                <br/>
                                <p>
                                    As a full-stack engineer, I love using my
                                    skills
                                    to build great products with a meaningful
                                    impact. My professional experience ranges
                                    from
                                    product management to software engineering
                                    across K-12 edtech and non-profit B2B
                                    software.
                                </p>
                                <br/>
                                <p>
                                    In my free time, I enjoy reading, going to
                                    the
                                    theater, and playing video games.
                                </p>
                            </Card.Text>

                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default BioCard
