import React from 'react'
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import {Card, Button, Container, Row, Col} from 'react-bootstrap'
import '../pages/projects/ProjectData.js';

/**
 * Responsiveness:
 * - Mobile--image should be on the top in the card and text should be below the image
 * - Desktop--image should be on the left and text should be on the right
 *
 * Header bar of card is the same color as the navbar.
 *
 */

const InfoCard = (props) => {
    return (
        <Card
            style={{
                maxWidth: '90%',
                maxHeight: "50%",
                margin: '0 auto',
                marginTop: '80px',
                backgroundColor: '#838495'
            }}
        >
            <Card.Header
                style={{
                    backgroundColor: '#4b6479',
                    textAlign: 'center',
                    color: '#b5cacb',
                    fontFamily: 'roboto mono'
                }}
            >
                <h1>{props.title}</h1>
            </Card.Header>
            <Card.Body
                // style={{
                //   backgroundColor: '#838495',
                //   borderRadius: '1em',
                //   display: 'flex',
                //   flexDirection: 'row',
                //   flexWrap: 'wrap'
                // }}
            >
                <Row>
                    <Col xs={12} md={6}>
                        <Card.Img
                            style={{
                                maxWidth: '90%',
                                marginTop: '10%',
                                marginBottom: '2.5%',
                                marginLeft: '5%'
                            }}
                            src={props.overviewImage}
                        ></Card.Img>
                    </Col>
                    <Col xs={12} md={6}>
                        <div
                            style={{
                                margin: '30% 5%',
                                fontFamily: 'montserrat',
                                fontSize: '1.5em',
                                color: '#D7DFE0',
                                textAlign: 'center'
                            }}
                        >
                            <Card.Text>
                                {props.overviewText}
                            </Card.Text>
                            <Link to={'/'+`${props.path}`}>
                                <Button
                                    // variant='primary'
                                    style={{
                                        backgroundColor: '#B5CACB',
                                        borderColor: '#B5CACB',
                                        color: '#4B6479',
                                        width: 'auto',
                                        height: '2.5em',
                                        fontSize: '1.2em'
                                    }}
                                >
                                    Learn More
                                </Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default InfoCard
