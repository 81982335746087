import React from 'react'
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import {Card, Button, Container, Row, Col} from 'react-bootstrap'
import projectData from '../../pages/projects/ProjectData';
import InfoCard from "../InfoCard";


const ProjectMenu = () => {

    return (
        <><br/><br/>
            {projectData.map((projects) => (
                <InfoCard
                    title = {projects.title}
                    overviewText = {projects.overview.contents}
                    overviewImage = {projects.overview.image}
                    path = {`projects/${projects.details.path}`}
                />
                ))}
<br/>
        </>
    )

}

export default ProjectMenu
