import React from 'react'
import Home from '../components/Home/Home'
import About from '../components/About/About'
const HomePage = () => {

    return (
        <>
            <Home/>
            <About/>
        </>
    )
}

export default HomePage
